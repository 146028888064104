






















































































































































































































































































































































import Vue from 'vue';
import StatusTag from '@/components/media/CommissionStatusTag.vue';
import sleep from '@/util/sleep';
import { removeResourceById, Resource } from '@/util/list';

interface FakeEvent extends Resource {
  id: string,
  type: string,
}

export default Vue.extend({
  components: {
    StatusTag,
  },
  data() {
    return {
      submitted: false,
      summary: '',
      events: [] as FakeEvent[],
      loading: false,
      status: 'requested',
      paid: [] as string[],
      counter: 0,
      uploading: false,
      triedOut: [] as string[],
    };
  },
  watch: {
    triedOut(to) {
      if (to.includes('message')
      && to.includes('file')
      && to.includes('pay')) {
        this.complete();
      }
    },
    paid(to) {
      if (to.length > 0) {
        this.uploadMakerFile();
      }
    },
    // events(to, from) {
    //   if (this.status === 'pending') {
    //     this.complete();
    //   }
    // },
  },
  methods: {
    async submit() {
      this.loading = true;

      await sleep(500);

      this.submitted = true;
      this.loading = false;

      await this.accept();
      await sleep(1500);
      await this.item();
    },
    async reject() {
      this.loading = true;

      await sleep(500);

      this.counter += 1;
      this.events.push({
        id: this.counter.toString(),
        type: 'rejected',
      });
      this.status = 'rejected';

      this.loading = false;
    },
    async accept() {
      this.loading = true;

      await sleep(500);

      this.counter += 1;
      this.events.push({
        id: this.counter.toString(),
        type: 'accepted',
      });
      this.status = 'active';

      this.loading = false;
    },
    async pending() {
      this.loading = true;

      await sleep(500);

      this.counter += 1;
      this.events.push({
        id: this.counter.toString(),
        type: 'pending',
      });
      this.status = 'pending';

      this.loading = false;
      this.triedOut.push('pending');
    },
    async active() {
      this.loading = true;

      await sleep(500);

      this.counter += 1;
      this.events.push({
        id: this.counter.toString(),
        type: 'active',
      });
      this.status = 'active';

      this.loading = false;
    },
    async complete() {
      await sleep(2500);

      this.counter += 1;
      this.events.push({
        id: this.counter.toString(),
        type: 'complete',
      });
      this.status = 'complete';
    },
    async message() {
      this.loading = true;

      await sleep(500);

      this.counter += 1;
      this.events.push({
        id: this.counter.toString(),
        type: 'message-sent',
      });

      this.loading = false;
      this.triedOut.push('message');

      window.setTimeout(() => {
        this.counter += 1;
        this.events.push({
          id: this.counter.toString(),
          type: 'message-received',
        });
      }, 1500);
    },
    async upload() {
      this.loading = true;
      this.uploading = true;

      await sleep(1750);

      this.counter += 1;
      this.events.push({
        id: this.counter.toString(),
        type: 'uploaded',
      });

      this.uploading = false;
      this.loading = false;
      this.triedOut.push('file');
    },
    async uploadMakerFile() {
      this.counter += 1;
      this.events.push({
        id: this.counter.toString(),
        type: 'upload',
      });
    },
    async deleteFile(eventId: string) {
      this.loading = true;

      await sleep(500);

      removeResourceById(this.events, eventId);

      this.counter += 1;
      this.events.push({
        id: this.counter.toString(),
        type: 'deleted',
      });

      this.loading = false;
    },
    async item() {
      await sleep(500);

      this.counter += 1;

      const id = this.counter.toString();

      this.events.push({
        id,
        type: 'item',
      });
    },
    async pay(id: string) {
      this.loading = true;

      await sleep(1000);

      this.loading = false;
      this.paid.push(id);
      this.counter += 1;
      this.events.push({
        id: this.counter.toString(),
        type: 'paid',
      });
      this.triedOut.push('pay');
    },
    async approve() {
      this.loading = true;

      await sleep(500);

      this.counter += 1;
      this.events.push({
        id: this.counter.toString(),
        type: 'approved',
      });
      this.status = 'finished';
      this.loading = false;
    },
    async disapprove() {
      this.loading = true;

      await sleep(500);

      this.counter += 1;
      this.events.push({
        id: this.counter.toString(),
        type: 'disapproved',
      });
      this.status = 'pending';
      this.loading = false;
    },
    async restart() {
      this.submitted = false;
      this.summary = '';
      this.events = [];
      this.counter = 0;
      this.paid = [];
      this.triedOut = [];
      this.status = 'requested';
    },
    getSvgComponentNameForEventType(type: string): string {
      let component;
      switch (type) {
        case 'accepted':
          component = 'Check';
          break;

        case 'rejected':
        case 'disapproved':
          component = 'XCircle';
          break;

        case 'uploaded':
          component = 'UploadCloud';
          break;

        case 'deleted':
          component = 'FileMinus';
          break;

        case 'item':
          component = 'PlusCircle';
          break;

        case 'paid':
          component = 'Unlock';
          break;

        case 'message-sent':
        case 'message-received':
          component = 'MessageCircle';
          break;

        case 'complete':
        case 'approved':
          component = 'CheckCircle';
          break;

        case 'active':
        case 'pending':
        default:
          component = 'InfoCircle';
          break;
      }

      return `Svg${component}`;
    },
  },
});
